import * as React from "react"
import { graphql } from "gatsby"
import { Header, Layout } from '../components'
import { TypeArtist, } from "../containers/Main"

class Artist extends React.Component {
  render() {
    const {
      data: {
        prismicArtiste: {
          uid,
          data: {
            description,
            artist_name,
            images          
          }
        }
      }
    } = this.props;

    return (
      <Layout
        context={{
          title: artist_name.text,
          description: description.text,
          slug: `/artistes/${uid}/`,
        }}
      >
        <TypeArtist>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h1>{artist_name.text}</h1>
            <p dangerouslySetInnerHTML={{ __html: description.html }}></p>
            {
              images.length > 0 && (
                <div className="img-block">
                  {
                    images.map((item, index) => {
                      return (
                        <React.Fragment key={`images-${index}`} >
                          <img 
                            data='item' 
                            src={item.image.localFile.publicURL} 
                            alt={"photo " + item.legend.text} 
                          />
                          {
                            (item.legend.html && item.legend.html !== '') && (
                              <p dangerouslySetInnerHTML={{ __html: item.legend.html }}></p>
                            )
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        </TypeArtist>
      </Layout>
    )
  }
}

export default Artist;
export const artisteQuery = graphql`
  query currentArtiste($slug: String!) {
    prismicArtiste(uid: { eq: $slug }) {
      uid
      data {
        description {
          html
        }
        status
        artist_name {
          text
        }
        images {
          image {
            localFile {
              publicURL
            }
          }
          legend {
            html
          }
        }
      }
    }
  }
`